<template>
  <div class="CleanAccount page">
    <div class="top">
      <a-input-search
        placeholder="input search text"
        style="width: 200px"
        enter-button
      />
      <a-dropdown>
        <a class="ant-dropdown-link" @click.prevent>
          <a-button type="primary">
            导出
            <DownOutlined />
          </a-button>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a href="javascript:;">导出Excel</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="{
          page: page,
          total: total,
        }"
        rowKey="user_id"
        @change="pageChange"
      >
        <template #operation="{ record }">
          <a-popconfirm
            title="Are you sure delete this task?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDel(record.user_id)"
          >
            <a-button size="small">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs } from "@vue/reactivity";
import { getTable, postDel } from "../../service/clearnaccount";
import { onMounted } from "@vue/runtime-core";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "序号",
    dataIndex: "user_id",
  },
  {
    title: "电话号码",
    dataIndex: "phone",
  },
  {
    title: "用户名",
    dataIndex: "username",
  },
  {
    title: "公司名称",
    dataIndex: "company_name",
  },
  {
    title: "类型",
    dataIndex: "type",
  },
  {
    title: "金额",
    dataIndex: "price",
  },
  {
    title: "操作",
    dataIndex: "operation",
    fixed: "right",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "CleanAccount",
  setup() {
    const state = reactive({
      page: 1,
      limit: 10,
      data: [],
      total: undefined,
    });
    onMounted(() => {
      getTableFun();
    });
    const getTableFun = async () => {
      const res = await getTable({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        limit: state.limit,
      });
      console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const pageChange = (e) => {
      state.page = e.current;
      getTableFun();
    };
    const handleDel = async (user_id) => {
      const res = await postDel({
        access_token: sessionStorage.getItem("token"),
        user_id,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        getTableFun();
      } else {
        message.error(res.data.msg);
      }
    };
    return {
      columns,
      ...toRefs(state),
      handleDel,
      pageChange,
    };
  },
  components: { DownOutlined },
};
</script>
<style  scoped>
.top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f7f7f7;
  padding: 15px;
}
.table {
  padding: 0 15px;
}
</style>